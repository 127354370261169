import { Typography } from "@mui/material";
import { IColumn } from "shared/ui/Table/constants";
import { ITriggerSource } from "store/interfaces/triggerSource";

export const columns: IColumn[] = [
  {
    label: "ID",
    field: "id",
  },
  {
    label: "Name",
    field: "name",
  },
  {
    label: "Title",
    field: "tittle",
  },
  {
    label: "Description",
    field: "description",
  },
  {
    label: "Service types",
    layout: (row: ITriggerSource) => {
      return <Typography>{row.serviceTypes?.length}</Typography>;
    },
  },
];
