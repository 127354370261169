import BasicTabs from "shared/ui/Tabs";
import { Box } from "@mui/system";
import TriggerServices from "../TriggerServices";
import TriggerSourcePage from "../TriggerSources";

enum ETriggerTab {
  Service = 0,
  Source,
}

const triggerTabData = [
  {
    index: ETriggerTab.Service,
    label: "Service type",
    children: <TriggerServices />,
  },
  {
    index: ETriggerTab.Source,
    label: "Triggers",
    children: <TriggerSourcePage />,
  },
];

const Trigger = () => {
  return (
    <Box>
      <BasicTabs tabsData={triggerTabData} />
    </Box>
  );
};

export default Trigger;
