import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import { Button, SvgIcon, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useAsyncDispatch } from "shared/helpers/hooks/useAsyncDispatch";
import SharedDialog from "shared/ui/Dialog";
import RightDrawer from "shared/ui/Drawer";
import BasicTable from "shared/ui/Table";
import { ERequestStatus } from "store/enums/index.enum";
import { ITriggerServices } from "store/interfaces/triggerServices";
import { setLoading, setTableLoading } from "store/slicers/common";
import {
  DeleteTriggerService,
  GetTriggerServices,
  selectTriggerServiceData,
} from "store/slicers/triggerServices";
import AddEditTriggerService from "./components/AddEditTriggerService";
import { columns } from "./constants";

const deleteServiceWarningConfig = {
  title: "warning",
  description: "delete_trigger_service",
};

const TriggerServices = () => {
  const [activeRow, setActiveRow] = useState<ITriggerServices>();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isWarningOpen, setWarningOpen] = useState(false);
  const dispatch = useAsyncDispatch();

  const data = useSelector(selectTriggerServiceData);

  const refetchServices = async () => {
    await dispatch(setTableLoading(true));
    await dispatch(GetTriggerServices());
    await dispatch(setTableLoading(false));
  };

  const handleEdit = async (row: ITriggerServices) => {
    setActiveRow(row);
    setDrawerOpen(true);
  };

  const handleClose = () => {
    setActiveRow(undefined);
  };

  const onFormSuccess = () => {
    setActiveRow(undefined);
    setDrawerOpen(false);
    refetchServices();
  };

  const handleOpenWarning = (row: ITriggerServices) => {
    setActiveRow(row as ITriggerServices);
    setWarningOpen(true);
  };

  const handleDelete = async () => {
    if (!activeRow) {
      return;
    }
    dispatch(setLoading(true));
    const { meta } = await dispatch(DeleteTriggerService(activeRow.id));
    if (meta.requestStatus !== ERequestStatus.FULFILLED) {
      dispatch(setLoading(false));
      return;
    }
    setActiveRow(undefined);
    await refetchServices();
    dispatch(setLoading(false));
    toast.success("Service is deleted");
  };

  const getActions = (rowData: ITriggerServices) => {
    return [
      {
        label: "Edit",
        onClick: () => handleEdit(rowData),
      },
      {
        label: "Delete",
        onClick: () => handleOpenWarning(rowData),
      },
    ];
  };

  const initialFetch = useCallback(async () => {
    await dispatch(setTableLoading(true));
    const { meta } = await dispatch(GetTriggerServices());
    if (meta.requestStatus === ERequestStatus.FULFILLED) {
      await dispatch(setTableLoading(false));
    } else {
      await dispatch(setTableLoading(false));
    }
  }, [dispatch]);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  return (
    <Box p={4}>
      <Box display="flex" justifyContent="space-between">
        <Typography
          variant="h4"
          fontWeight={500}
          sx={{ color: "text.secondary" }}
        >
          Service Types
        </Typography>
        <Button
          startIcon={
            <SvgIcon fontSize="small">
              <PlusIcon />
            </SvgIcon>
          }
          variant="contained"
          onClick={() => setDrawerOpen(true)}
        >
          Add
        </Button>
      </Box>
      <BasicTable<ITriggerServices>
        toolbar={false}
        columns={columns}
        data={data || []}
        onChange={refetchServices}
        getActions={getActions}
        enablePagination={false}
        sortable={false}
      />
      <RightDrawer
        open={isDrawerOpen}
        setOpen={setDrawerOpen}
        onClose={handleClose}
        title={`${activeRow ? "Edit" : "Add"} Service Types`}
      >
        <AddEditTriggerService editData={activeRow} onSuccess={onFormSuccess} />
      </RightDrawer>
      <SharedDialog
        open={isWarningOpen}
        setOpen={setWarningOpen}
        onSuccess={handleDelete}
        textConfig={deleteServiceWarningConfig}
      />
    </Box>
  );
};

export default TriggerServices;
