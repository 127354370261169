import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import { Button, SvgIcon, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useAsyncDispatch } from "shared/helpers/hooks/useAsyncDispatch";
import SharedDialog from "shared/ui/Dialog";
import RightDrawer from "shared/ui/Drawer";
import BasicTable from "shared/ui/Table";
import { ERequestStatus } from "store/enums/index.enum";
import { ITriggerSource } from "store/interfaces/triggerSource";
import { setLoading, setTableLoading } from "store/slicers/common";
import { GetTriggerServices } from "store/slicers/triggerServices";
import {
  DeleteTriggerSource,
  GetTriggerSources,
  selectTriggerSourceData,
} from "store/slicers/triggerSource";
import AddEditTriggerSource from "./components/AddEditTriggerSource";
import { columns } from "./constants";

const deleteServiceWarningConfig = {
  title: "warning",
  description: "delete_service",
};

const TriggerSourcePage = () => {
  const [activeRow, setActiveRow] = useState<ITriggerSource>();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isWarningOpen, setWarningOpen] = useState(false);
  const dispatch = useAsyncDispatch();
  const data = useSelector(selectTriggerSourceData);

  const refetchSources = useCallback(async () => {
    await dispatch(setTableLoading(true));
    await dispatch(GetTriggerSources());
    await dispatch(setTableLoading(false));
  }, [dispatch]);

  const handleEdit = async (row: ITriggerSource) => {
    setActiveRow(row);
    setDrawerOpen(true);
  };

  const handleClose = () => {
    setActiveRow(undefined);
  };

  const onFormSuccess = () => {
    setActiveRow(undefined);
    setDrawerOpen(false);
    refetchSources();
  };

  const handleOpenWarning = (row: ITriggerSource) => {
    setActiveRow(row);
    setWarningOpen(true);
  };

  const handleDelete = async () => {
    if (!activeRow) {
      return;
    }
    dispatch(setLoading(true));
    const { meta } = await dispatch(DeleteTriggerSource(activeRow.id));
    if (meta.requestStatus !== ERequestStatus.FULFILLED) {
      dispatch(setLoading(false));
      return;
    }
    setActiveRow(undefined);
    await refetchSources();
    dispatch(setLoading(false));
    toast.success("Service is deleted");
  };

  const getActions = (rowData: ITriggerSource) => {
    return [
      {
        label: "Edit",
        onClick: () => handleEdit(rowData),
      },
      {
        label: "Delete",
        onClick: () => handleOpenWarning(rowData),
      },
    ];
  };

  useEffect(() => {
    Promise.all([dispatch(GetTriggerServices()), refetchSources()]);
  }, [refetchSources, dispatch]);

  return (
    <Box p={4}>
      <Typography
        variant="h4"
        fontWeight={500}
        sx={{ color: "text.secondary" }}
      >
        Triggers
      </Typography>
      <Box textAlign={"right"}>
        <Button
          startIcon={
            <SvgIcon fontSize="small">
              <PlusIcon />
            </SvgIcon>
          }
          variant="contained"
          onClick={() => setDrawerOpen(true)}
        >
          Add
        </Button>
      </Box>
      <BasicTable<ITriggerSource>
        toolbar={false}
        columns={columns}
        data={data || []}
        onChange={refetchSources}
        getActions={getActions}
        enablePagination={false}
        sortable={false}
      />
      <RightDrawer
        open={isDrawerOpen}
        setOpen={setDrawerOpen}
        onClose={handleClose}
        title={`${activeRow ? "Edit" : "Add"} Service Types`}
      >
        <AddEditTriggerSource editData={activeRow} onSuccess={onFormSuccess} />
      </RightDrawer>
      <SharedDialog
        open={isWarningOpen}
        setOpen={setWarningOpen}
        onSuccess={handleDelete}
        textConfig={deleteServiceWarningConfig}
      />
    </Box>
  );
};

export default TriggerSourcePage;
