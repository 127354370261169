import EyeIcon from "@heroicons/react/24/solid/EyeIcon";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Paper,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAsyncDispatch } from "shared/helpers/hooks/useAsyncDispatch";
import useTranslation from "shared/helpers/hooks/useTranslation";
import { requiredRules } from "shared/helpers/validators";
import BasicAutocomplete from "shared/ui/Autocomplete";
import ButtonLoader from "shared/ui/ButtonLoader";
import SharedDialog from "shared/ui/Dialog";
import TextInput from "shared/ui/TextInput";
import { EDistributionMethods } from "store/enums/campaignDetails";
import { ERequestStatus } from "store/enums/index.enum";
import {
  DistributionSchedule,
  GetCampaignById,
  selectCampaignInfo,
} from "store/slicers/campaignDetail";
import {
  selectButtonLoadingState,
  setButtonLoading,
} from "store/slicers/common";
import {
  GetTriggerSources,
  selectTriggerSourceData,
} from "store/slicers/triggerSource";

const defaultValues = {
  ignoreQuarantine: false,
  message: "",
  numberOfTransaction: "",
  postpone: false,
  postponeTime: "",
  quarantinePeriod: "",
  surveyQuarantine: false,
  triggerIDs: [],
  title: "",
};

const PushDistribution = () => {
  const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);
  const t = useTranslation();
  const { surveyID } = useParams();
  const campaignInfo = useSelector(selectCampaignInfo);
  const dispatch = useAsyncDispatch();
  const isButtonLoading = useSelector(selectButtonLoadingState);
  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });
  const { watch } = methods;
  const triggers = useSelector(selectTriggerSourceData);

  const onSubmit = async (formData) => {
    dispatch(setButtonLoading(true));
    const { triggerIDs, ...rest } = formData;

    const data = {
      ...rest,
      triggerIDs: triggerIDs.map((item) => {
        return item.id;
      }),
      distributionMethod: EDistributionMethods.Push,
      isLink: true,
    };
    const { meta } = await dispatch(
      DistributionSchedule({ id: Number(surveyID), data })
    );

    if (meta.requestStatus !== ERequestStatus.FULFILLED) {
      dispatch(setButtonLoading(false));
      return;
    }

    toast.success("Campaign schedule is set.");
    dispatch(setButtonLoading(false));
  };

  const openPreviewModal = () => {
    setPreviewModalOpen(true);
  };

  useEffect(() => {
    const distributionInfo = campaignInfo?.campaignTriggers.find(
      (c) => c.distributionMethod === EDistributionMethods.Push
    );
    if (distributionInfo) {
      methods.reset({
        name: campaignInfo.name,
        ignoreQuarantine: campaignInfo.ignoreQuarantine,
        message: campaignInfo.message,
        numberOfTransaction: campaignInfo?.numberOfTransaction
          ? campaignInfo?.numberOfTransaction?.toString()
          : "",
        postpone: campaignInfo.postpone,
        postponeTime: campaignInfo?.postponeTime
          ? campaignInfo.postponeTime.toString()
          : "",
        quarantinePeriod: campaignInfo?.quarantinePeriod
          ? campaignInfo.quarantinePeriod.toString()
          : "",
        surveyQuarantine: campaignInfo.surveyQuarantine,
        triggerIDs: triggers.filter((i) =>
          campaignInfo.triggerIDs.includes(i.id)
        ),
      });
    }
  }, [campaignInfo, methods, triggers]);

  useEffect(() => {
    Promise.all([
      dispatch(GetTriggerSources()),
      dispatch(GetCampaignById(Number(surveyID))),
    ]);
  }, [dispatch, surveyID]);

  return (
    <Box p={4} m={2} component={Paper}>
      <FormProvider {...methods}>
        <Box display="flex">
          <Box flex={1}>
            <Typography fontSize={18} mb={2}>
              {t("schedule")}
            </Typography>
          </Box>
          <Box flex={1}>
            <Box
              ml={6}
              mb={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography fontSize={18}>Content</Typography>
              <Button
                onClick={openPreviewModal}
                variant="outlined"
                startIcon={<EyeIcon height={20} />}
                disabled={!watch("title") || !watch("message")}
              >
                <Typography>Preview</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent={"space-between"}>
          <Box flex={1}>
            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems={"stretch"}
              mb={2}
            >
              <Box flex={1} display="flex" alignItems="center">
                <Typography>{t("name")}</Typography>
              </Box>
              <Box flex={1}>
                <TextInput rules={requiredRules} label="name" name="name" />
              </Box>
            </Box>

            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems={"stretch"}
              mb={2}
            >
              <Box flex={1} display="flex" alignItems="center">
                <Tooltip
                  title={
                    "Here you can select the trigger source from where you want to receive the triggers. "
                  }
                >
                  <Typography>{t("trigger_source")}</Typography>
                </Tooltip>
              </Box>
              <Box flex={1}>
                <BasicAutocomplete
                  options={triggers}
                  inputLabel={"Triggers"}
                  name={"triggerIDs"}
                  onChangeCB={() => methods.trigger("triggerIDs")}
                  rules={requiredRules}
                  optionLabel="name"
                  defaultValue={[]}
                  multiple
                />
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems={"stretch"}
              mb={2}
            >
              <Box flex={1} display="flex" alignItems="center">
                <Tooltip
                  title={
                    "In case activating this function the survey will be sent to the customer after the predefined minutes.  "
                  }
                >
                  <Typography>{t("postpone_survey")}</Typography>
                </Tooltip>
              </Box>
              <Box flex={1}>
                <Controller
                  name="postpone"
                  control={methods.control}
                  render={({ field }) => (
                    <Switch color="success" {...field} checked={field.value} />
                  )}
                />
              </Box>
            </Box>
            {methods.watch("postpone") && (
              <Box
                display="flex"
                justifyContent={"space-between"}
                alignItems={"stretch"}
                mb={2}
              >
                <Box flex={1} display="flex" alignItems="center">
                  <Typography>{t("postpone_survey_for")}</Typography>
                </Box>
                <Box flex={1}>
                  <TextInput
                    rules={requiredRules}
                    label="minutes"
                    name="postponeTime"
                  />
                </Box>
              </Box>
            )}
            <Divider />
          </Box>
          <Box flex={1}>
            <Box ml={6}>
              <Box mb={2}>
                <TextInput name="title" label="Title" />
              </Box>
              <TextInput name="message" label="Content" />
            </Box>
          </Box>
        </Box>
        <Typography mt={2} fontSize={18} mb={2}>
          {t("quarantine")}
        </Typography>
        <Box display="flex" justifyContent={"space-between"}>
          <Box flex={1}>
            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems={"stretch"}
              mb={2}
            >
              <Box flex={1} display="flex" alignItems="center">
                <Tooltip
                  title={
                    "In case activating this function the customers will be quarantined after the predefined transactions."
                  }
                >
                  <Typography>{t("customer_quarantine")}</Typography>
                </Tooltip>
              </Box>
              <Box flex={1}>
                <Controller
                  name="surveyQuarantine"
                  control={methods.control}
                  render={({ field }) => (
                    <Switch color="success" {...field} checked={field.value} />
                  )}
                />
              </Box>
            </Box>
            {methods.watch("surveyQuarantine") && (
              <Fragment>
                <Box
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems={"stretch"}
                  mb={2}
                >
                  <Box flex={1} display="flex" alignItems="center">
                    <Typography>{t("quarantine_the_customer")}</Typography>
                  </Box>
                  <Box flex={1}>
                    <TextInput
                      rules={requiredRules}
                      label="transaction"
                      name="numberOfTransaction"
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems={"stretch"}
                  mb={2}
                >
                  <Box flex={1} display="flex" alignItems="center">
                    <Typography>{t("quarantine_the_customer_for")}</Typography>
                  </Box>
                  <Box flex={1}>
                    <TextInput
                      rules={requiredRules}
                      label="days"
                      name="quarantinePeriod"
                    />
                  </Box>
                </Box>
              </Fragment>
            )}
            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems={"stretch"}
              mb={2}
            >
              <Box flex={1} mb={6} display="flex" alignItems="center">
                <Tooltip
                  title={
                    "In case activation of this function the survey will be sent the quarantined customers as well after receiving the trigger."
                  }
                >
                  <Typography>
                    {t("include_the_quarantined_customers")}
                  </Typography>
                </Tooltip>
              </Box>
              <Box flex={1}>
                <Controller
                  name="ignoreQuarantine"
                  control={methods.control}
                  render={({ field }) => (
                    <Switch color="success" {...field} checked={field.value} />
                  )}
                />
              </Box>
            </Box>
          </Box>
          <Box flex={1} />
        </Box>
      </FormProvider>
      <Box
        mt={2}
        width="100%"
        p={2}
        sx={{
          zIndex: 1100,
          position: "fixed",
          bottom: 0,
          right: 0,
          border: "1px solid",
          borderColor: "divider",
          backgroundColor: "white",
        }}
        display="flex"
        justifyContent={"flex-end"}
      >
        <Box>
          <ButtonLoader
            fullWidth
            onClick={methods.handleSubmit(onSubmit)}
            isLoading={isButtonLoading}
            disabled={!Object.keys(methods.formState.dirtyFields)?.length}
            type="submit"
          >
            <Typography>{t("save")}</Typography>
          </ButtonLoader>
        </Box>
      </Box>
      <SharedDialog
        hasActions={false}
        maxWidth="xs"
        minWidth="300px"
        open={isPreviewModalOpen}
        setOpen={setPreviewModalOpen}
        textConfig={{ title: "Preview" }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiDialogContent-root": {
              padding: 0,
            },
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Card
            sx={{ height: 500, border: "4px solid #d9d9d9", maxWidth: 270 }}
          >
            <CardContent sx={{ p: 1 }}>
              <Paper elevation={24} sx={{ mt: 3, p: 1.5 }}>
                <Box
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center" gap={0.5}>
                    <Box
                      sx={{
                        width: 15,
                        height: 15,
                        borderRadius: 0.5,
                        backgroundColor: "#d9d9d9",
                      }}
                    />
                    <Typography color="grey" fontSize={12}>
                      App name
                    </Typography>
                  </Box>
                  <Typography color="grey" fontSize={10}>
                    now
                  </Typography>
                </Box>
                <Typography fontWeight="bold" mt={0.5} fontSize={12}>
                  {watch("title")}
                </Typography>
                <Typography mt={0.5} fontSize={12}>
                  {watch("message")}
                </Typography>
              </Paper>
            </CardContent>
          </Card>
        </Box>
      </SharedDialog>
    </Box>
  );
};

export default PushDistribution;
