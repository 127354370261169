import { IColumn } from "shared/ui/Table/constants";

export const columns: IColumn[] = [
  {
    label: "ID",
    field: "id",
  },
  {
    label: "Name",
    field: "name",
  },
  {
    label: "Type",
    field: "typeId",
  },
];
