import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EBaseUrl } from "index";
import thunkOptions from "store/config/thunkOptions";
import { IState } from "store/interfaces/main";
import {
  IAddEditTriggerSource,
  ITriggerSource,
  ITriggerSourceState,
} from "store/interfaces/triggerSource";
import { api } from "store/services/apiService";

const name = "TRIGGER_SOURCE";

const initialState: ITriggerSourceState = { listData: [] };

export const GetTriggerSources = createAsyncThunk<ITriggerSource[]>(
  `${name}/GetTriggerSources`,
  async () => {
    return (await api.get(`${EBaseUrl.API}/Trigger/All`)).data;
  },
  thunkOptions
);

export const AddTriggerSource = createAsyncThunk<
  ITriggerSource,
  IAddEditTriggerSource
>(
  `${name}/AddTriggerSource`,
  async (formData) => {
    return (await api.post(`${EBaseUrl.API}/Trigger/Create`, formData)).data;
  },
  thunkOptions
);

export const UpdateTriggerSource = createAsyncThunk<
  ITriggerSource,
  IAddEditTriggerSource
>(
  `${name}/UpdateTriggerSource`,
  async (formData) => {
    return (
      await api.put(`${EBaseUrl.API}/Trigger/Update/${formData?.id}`, formData)
    ).data;
  },
  thunkOptions
);

export const DeleteTriggerSource = createAsyncThunk<ITriggerSource, number>(
  `${name}/DeleteTriggerSource`,
  async (id) => {
    return (await api.delete(`${EBaseUrl.API}/Trigger/${id}`)).data;
  },
  thunkOptions
);

const triggerSourceSlice = createSlice({
  initialState,
  name,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetTriggerSources.fulfilled, (state, { payload }) => {
      state.listData = payload;
    });
  },
});

export const selectTriggerSourceData = (state: IState) =>
  state.triggerSource.listData;

export default triggerSourceSlice.reducer;
